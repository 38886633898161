import React from 'react';
import { useResponsive } from '@app/hooks/useResponsive';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import Product from '@app/components/product/Product';

import 'pages/css/search.css';
import 'pages/css/common.css';

import QuestionList from '@app/components/question/QuestionList';
import WayHome from '@app/components/map/WayHome';
import Address from '@app/components/map/Address';

const DashboardPage: React.FC = () => {
  const { isDesktop } = useResponsive();

  const slideImages = Array.from({ length: 2 }, (_, i) => `/img/슬라이드${i + 1}.JPG`);
  const itemsPerRow = isDesktop ? 2 : 1;

  const rows = Array.from({ length: Math.ceil(slideImages.length / itemsPerRow) }, (_, i) =>
    slideImages.slice(i * itemsPerRow, i * itemsPerRow + itemsPerRow),
  );

  return (
    <>
      <PageTitle></PageTitle>
      <main>
        <div id="contents">
          <section className="visual_area">
            <div className="inner">
              <div className="visual_title">
                <div>
                  <h1>(주) 제이피드림</h1>
                  <h2>반도체 첨단소재 전문기업</h2>
                  <p className="txt2">첨단소재의 미래를 열어갑니다.</p>
                </div>
              </div>
            </div>
          </section>

          <section className="product_area">
            <div className="inner">
              {rows.map((row, rowIndex) => (
                <div key={rowIndex} className="product_row">
                  {row.map((src, colIndex) => (
                    <Product key={colIndex} src={src} />
                  ))}
                </div>
              ))}
            </div>
          </section>

          <section className="question_area">
            <div className="inner">
              <QuestionList />
            </div>
          </section>

          <section className="map_area">
            <div className="inner">
              <WayHome />
            </div>
          </section>

          <section className="map_area">
            <div className="inner">
              <Address />
            </div>
          </section>
        </div>
      </main>
    </>
  );
};

export default DashboardPage;
