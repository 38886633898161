import styled from 'styled-components';
import { Checkbox, Button } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Input as CommonInput } from '@app/components/common/inputs/Input/Input';
import { InputPassword as CommonInputPassword } from '@app/components/common/inputs/InputPassword/InputPassword';
// import loginBackground from '@app/assets/images/login-bg.webp';
import { BORDER_RADIUS, FONT_SIZE, FONT_WEIGHT, media } from '@app/styles/themes/constants';

export const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
`;

export const BackgroundWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #2d387f;
  background-size: cover;
  position: relative;
`;

export const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const FormWrapper = styled.div`
  // padding: 2.5rem;
  width: 28.125rem;
  overflow: auto;
  // background-color: rgba(var(--background-rgb-color), 0.93);
  // border-radius: ${BORDER_RADIUS};

  // @media only screen and ${media.xs} {
  //   padding: 2.5rem 1.25rem;
  //   width: 20.75rem;
  //   max-height: calc(100vh - 3rem);
  // }

  // @media only screen and ${media.md} {
  //   padding: 2.5rem;
  //   width: 31.75rem;
  //   max-height: calc(100vh - 3rem);
  // }
`;

export const ImgWrapper = styled.div`
  height: 245px;
  text-align: center;
  background: url('./login_img.png') center no-repeat;
  position: relative;

  &:before {
    content: '(주)제이피드림';
    position: absolute;
    left: 50%;
    bottom: 10%;
    transform: translate(-50%, -50%);
  }
`;

export const FormTitle = styled.div`
  color: var(--white);

  @media only screen and ${media.xs} {
    margin-bottom: 0.625rem;
    font-size: ${FONT_SIZE.lg};
    font-weight: ${FONT_WEIGHT.bold};
    line-height: 1.5625rem;
  }

  @media only screen and ${media.md} {
    margin-bottom: 0.875rem;
    font-size: ${FONT_SIZE.xxl};
    font-weight: ${FONT_WEIGHT.bold};
    line-height: 1.9375rem;
  }

  @media only screen and ${media.xl} {
    margin-bottom: 2.188rem;
    font-size: ${FONT_SIZE.xxxl};
    font-weight: ${FONT_WEIGHT.extraBold};
    line-height: 2.125rem;
  }
`;

export const FormCheckbox = styled(Checkbox)`
  display: flex;
  padding-left: 0.125rem;

  & .ant-checkbox-inner {
    border-radius: 3px;
    transform: scale(1.375);
  }

  & .ant-checkbox-input {
    transform: scale(1.375);
  }
`;

export const FormItem = styled(BaseForm.Item)`
  margin: 0.75rem 0 0.75rem 0;
  & .ant-form-item-control-input {
    min-height: 3.125rem;
  }

  & .ant-form-item-explain-error {
    font-size: ${FONT_SIZE.xs};
  }

  & label {
    color: var(--white) !important;
    font-size: var(--font-ss);
    line-height: 1.25rem;
  }

  &.ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
    padding-right: 1.5rem;
  }
`;

export const FormInput = styled(CommonInput)`
  color: var(--orange);
  background: transparent;
  border-radius: 0;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid var(--white);
  padding: 0 0 var(--base_size) 0;

  & input.ant-input {
    background: transparent;
  }

  & input::placeholder {
    color: transparent;
  }
`;

export const FormInputPassword = styled(CommonInputPassword)`
  color: var(--orange);
  background: transparent;
  border-radius: 0;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid var(--white);
  padding: 0 0 var(--base_size) 0;

  & input.ant-input {
    background: transparent;
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const Text = styled.span`
  color: var(--white);
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.regular};
`;

export const LinkText = styled(Text)`
  font-size: var(--font-m);
  text-decoration: underline;
  color: #06cbe2;
  font-weight: var(--weight-b);
`;

export const SubmitButton = styled(Button)`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  width: 100%;
  background: #06cbe2;
  border: 0;
  border-radius: 0.938rem;
  margin: 1.25rem 0 2.188rem 0;
`;

export const SocialButton = styled(Button)`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  width: 100%;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
`;

export const FooterWrapper = styled.div``;

export const BackIcon = styled(LeftOutlined)`
  font-size: 0.75rem;
  margin-right: 0.75rem;
`;

export interface BackWrapperProps {
  children?: React.ReactNode;
  onClick?: (e: React.MouseEvent) => void;
}

export const BackWrapper = styled.div<BackWrapperProps>`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 1.25rem;
`;

export const SocialIconWrapper = styled.div`
  display: flex;
  margin-right: 0.8125rem;
  @media only screen and ${media.xs} {
    margin-right: 0.625rem;
  }

  @media only screen and ${media.md} {
    margin-right: 0.8125rem;
  }
`;
