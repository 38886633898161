export interface User {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
}

export const userClass: User = {
  id: '',
  firstname: '',
  lastname: '',
  email: '',
};
