import React, { useState } from 'react';
import { Button, Popconfirm, message } from 'antd';
import { ColumnType } from 'antd/lib/table/interface';
import { Question } from '@app/types/types';
import { useMutation } from '@apollo/client';
import { DELETE_QUESTION_MUTATION } from '@app/graphql/questionMutation';
import QuestionModal from './QuestionModal';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

type EditActionsProps = {
  record: Question;
  refetchQuestionList: () => void;
};

const EditActions: React.FC<EditActionsProps> = ({ record, refetchQuestionList }) => {
  const [deleteQuestion] = useMutation(DELETE_QUESTION_MUTATION);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState<Question | null>(null);

  const onEdit = async (record: Question) => {
    setModalData(record);
    setIsModalVisible(true);
  };

  const onDelete = async (id: number) => {
    try {
      await deleteQuestion({ variables: { id } });
      refetchQuestionList();
      message.success(record.name + ' is successfully deleted');
    } catch (error) {
      message.error(`${error}`);
    }
  };

  const onSubmitModal = () => {
    setIsModalVisible(false);
    refetchQuestionList();
    message.success(record.name + ' is successfully updated');
  };

  return (
    <span>
      <Button type="link" onClick={() => onEdit(record)} icon={<EditOutlined />} />
      <Popconfirm title="정말로 삭제하시겠습니까?" onConfirm={() => onDelete(record.id)}>
        <Button type="link" icon={<DeleteOutlined style={{ color: 'red' }} />} />
      </Popconfirm>
      {isModalVisible && (
        <QuestionModal
          question={modalData}
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          onSubmit={() => onSubmitModal()}
        />
      )}
    </span>
  );
};

type QuestionColumnsProps = {
  questions: Question[];
  refetchQuestionList: () => void;
};

export const QuestionColumns = (props: QuestionColumnsProps): ColumnType<Question>[] => {
  const renderCell = (record: Question, dataIndex: keyof Question) => {
    let cellValue = record[dataIndex];

    cellValue = cellValue !== null && cellValue !== undefined ? cellValue.toString() : '';
    if (cellValue.includes('\n')) {
      return (
        <>
          {cellValue.split('\n').map((line, index) => (
            <span key={index}>
              {line}
              <br />
            </span>
          ))}
        </>
      );
    }

    return cellValue;
  };

  const columns: ColumnType<Question>[] = [
    {
      title: 'Actions',
      key: 'actions',
      width: '10%',
      render: (record: Question) => <EditActions record={record} refetchQuestionList={props.refetchQuestionList} />,
    },
    { title: '제목', key: 'title', render: (record: Question) => renderCell(record, 'title') },
    { title: '문의사항', key: 'contents', render: (record: Question) => renderCell(record, 'contents') },
    { title: '글쓴이', key: 'name', render: (record: Question) => renderCell(record, 'name') },
    // { title: '전화번호', key: 'phone', render: (record: Question) => renderCell(record, 'phone') },
    // { title: '이메일', key: 'email', render: (record: Question) => renderCell(record, 'email') },
  ];

  return columns;
};

export default QuestionColumns;
