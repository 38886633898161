import React from 'react';
import { DatePicker } from 'antd';
import { FormInstance } from 'antd/lib/form';
import moment, { Moment } from 'moment';

interface InputDatePickerProps {
  name: string;
  placeholder?: string | Date | number;
  form: FormInstance;
}

const InputDatePicker: React.FC<InputDatePickerProps> = ({ name, placeholder, form }) => {
  let momentDate: Moment | undefined = undefined;

  console.log('InputDatePicker', name, placeholder);

  if (placeholder) {
    const parsedDate = moment(placeholder);
    momentDate = parsedDate.isValid() ? parsedDate : undefined;
  }

  return (
    <DatePicker
      defaultValue={momentDate}
      allowClear
      onChange={(date: Moment | null, dateString: string | string[]) => {
        form.setFieldsValue({ [name]: dateString || null });
      }}
    />
  );
};

export default InputDatePicker;
