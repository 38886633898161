import React, { useEffect, useRef } from 'react';
import { Wrapper, Status } from '@googlemaps/react-wrapper';

const render = (status: Status) => {
  return <h1>{status}</h1>;
};

function MyMapComponent({ center, zoom }: { center: google.maps.LatLngLiteral; zoom: number }) {
  const mapRef = useRef<HTMLDivElement | null>(null);
  const markersRef = useRef<google.maps.Marker[]>([]);

  useEffect(() => {
    if (mapRef.current) {
      const map = new window.google.maps.Map(mapRef.current, {
        center,
        zoom,
      });

      markersRef.current.forEach((marker) => {
        marker.setMap(null);
      });

      // Create a marker and attach it to the map
      const marker = new window.google.maps.Marker({
        position: center,
        map,
        title: '제이피드림',
        optimized: false,
      });
      markersRef.current = [marker];
    }
  }, [center, zoom]);

  return <div ref={mapRef} id="map" style={{ width: '100%', height: '50vh', margin: 'auto' }} />;
}

const WayHome: React.FC = () => {
  const center = { lat: 37.2117, lng: 127.08968 };
  const zoom = 14;

  return (
    <Wrapper apiKey={'AIzaSyC9_jvpp5IiB68qeczw9EGrWeBs72EJ3n4'} render={render}>
      <MyMapComponent center={center} zoom={zoom} />
    </Wrapper>
  );
};

export default WayHome;
