import React from 'react';
import { User } from './User.type';
import { Button } from 'antd';
// import { useTranslation } from 'react-i18next';
import { EditOutlined } from '@ant-design/icons';
import EditUser from './EditUser';

/* eslint-disable */

interface Props {
  user: User;
}

const UserPage = (props: Props) => {
  const user: User = props.user;
  const [editMode, setEditMode] = React.useState(false);
  // const { t } = useTranslation();

  return (
    <>
      {editMode ? (
        <EditUser
          user={user}
          onUpdate={() => setEditMode(false)}
          onCancel={() => setEditMode(false)}
        />
      ) : (
        <Button type="link" style={{ color: 'red' }} icon={<EditOutlined />} onClick={() => setEditMode(true)} />
      )}
    </>
  );
};
export default UserPage;
