import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, message, TablePaginationConfig } from 'antd';
import { Table } from '@app/components/common/Table/Table';
import { Pagination } from 'api/table.api';
import { useMounted } from '@app/hooks/useMounted';
import { useQuery } from '@apollo/client';
import { GET_QUESTIONLIST_QUERY } from '@app/graphql/questionMutation';
import { Question, GetQuestionListQueryResult } from '@app/types/types';
import { QuestionColumns } from './QuestionColumns';
import QuestionModal from './QuestionModal';
import Search from '@app/pages/Search';

const initialPagination: Pagination = {
  current: 1,
  pageSize: 5,
  showSizeChanger: false,
};

const QuestionList: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState<Question | null>(null);

  const navigate = useNavigate();
  const queryString = require('query-string');
  const parsed = queryString.parse(window.location.hash);
  const page = parsed.page ? Number(parsed.page) : 1;

  const { data: questionList, refetch: refetchQuestionList } = useQuery<GetQuestionListQueryResult>(
    GET_QUESTIONLIST_QUERY,
    {
      variables: {
        search: parsed.search || '',
        page,
      },
    },
  );

  const [tableData, setTableData] = useState<{ data: Question[]; pagination: Pagination; loading: boolean }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });
  const { isMounted } = useMounted();

  const fetch = useCallback(
    (pagination: Pagination) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));
      if (isMounted.current) {
        const questions = questionList?.findManyQuestion?.questions ?? [];
        setTableData({ data: questions, pagination: pagination, loading: false });
      }
    },
    [isMounted, questionList],
  );

  useEffect(() => {
    initialPagination.current = page;
    initialPagination.pageSize = questionList?.findManyQuestion?.take;
    initialPagination.total = questionList?.findManyQuestion?.count;

    fetch(initialPagination);
  }, [fetch, questionList, page]);

  const columns = QuestionColumns({
    questions: questionList?.findManyQuestion?.questions || [],
    refetchQuestionList,
  });

  const closeModal = () => {
    setIsModalVisible(false);
    setModalData(null);
  };

  const onSubmitModal = (newQuestion: Question) => {
    closeModal();
    if (newQuestion.id) {
      message.success('Question is successfully updated');
    } else {
      message.success('Question is successfully created');
    }
    refetchQuestionList();
  };

  const handleTableChange = (pagination: TablePaginationConfig) => {
    parsed.page = pagination.current;
    navigate('?' + queryString.stringify(parsed));
  };

  return (
    <>
      <div style={{ marginBottom: '16px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Button type="primary" onClick={() => setIsModalVisible(true)} style={{ marginRight: '8px' }}>
          New Question
        </Button>
        <Search />
      </div>
      {questionList && (
        <Table
          columns={columns}
          dataSource={tableData.data}
          pagination={tableData.pagination}
          loading={tableData.loading}
          onChange={handleTableChange}
          rowKey={(record: Question) => String(record.id)}
        />
      )}
      {isModalVisible && (
        <QuestionModal question={modalData} visible={isModalVisible} onCancel={closeModal} onSubmit={onSubmitModal} />
      )}
    </>
  );
};

export default QuestionList;
