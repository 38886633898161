import React from 'react';
import { useResponsive } from '@app/hooks/useResponsive';
import Product from '@app/components/product/Product';

import 'pages/css/search.css';
import 'pages/css/common.css';

const ProductListPage: React.FC = () => {
  const { isDesktop } = useResponsive();

  const slideImages = Array.from({ length: 12 }, (_, i) => `/img/슬라이드${i + 1}.JPG`);
  const itemsPerRow = isDesktop ? 2 : 1;

  const rows = Array.from({ length: Math.ceil(slideImages.length / itemsPerRow) }, (_, i) =>
    slideImages.slice(i * itemsPerRow, i * itemsPerRow + itemsPerRow),
  );

  return (
    <div>
      <section className="product_area">
        <div className="inner">
          {rows.map((row, rowIndex) => (
            <div key={rowIndex} className="product_row">
              {row.map((src, colIndex) => (
                <Product key={colIndex} src={src} />
              ))}
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default ProductListPage;
