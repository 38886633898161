import React from 'react';
import QuestionList from '@app/components/question/QuestionList';

const QuestionListPage: React.FC = () => {
  return (
    <section className="question_area">
      <div className="inner">
        <QuestionList />
      </div>
    </section>
  );
};

export default QuestionListPage;
