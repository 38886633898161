import React from 'react';
import { Link } from 'react-router-dom';

import { AppstoreOutlined, HomeOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { MdAssistantNavigation } from 'react-icons/md';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';

interface DesktopHeaderProps {
  isTwoColumnsLayout: boolean;
}

const commonItemStyle = {
  fontSize: '18px',
  fontWeight: 'bold',
};

const items: MenuProps['items'] = [
  {
    label: (
      <Link to="/" style={commonItemStyle}>
        홈
      </Link>
    ),
    key: 'home',
    icon: <HomeOutlined style={commonItemStyle} />,
  },
  {
    label: (
      <Link to="/productList" style={commonItemStyle}>
        제품소개
      </Link>
    ),
    key: 'productList',
    icon: <AppstoreOutlined style={commonItemStyle} />,
  },
  {
    label: (
      <Link to="/questionList" style={commonItemStyle}>
        문의하기
      </Link>
    ),
    key: 'questionList',
    icon: <QuestionCircleOutlined style={commonItemStyle} />,
  },
  {
    label: (
      <Link to="/wayHome" style={commonItemStyle}>
        오시는 길
      </Link>
    ),
    key: 'wayHome',
    icon: <MdAssistantNavigation style={commonItemStyle} />,
  },
];

// eslint-disable-next-line
export const DesktopHeader: React.FC<DesktopHeaderProps> = ({ isTwoColumnsLayout }) => {
  return <Menu mode="horizontal" items={items} />;
};
