import React from 'react';
import { Row, Col, Space, Divider } from 'antd';
import { EnvironmentOutlined, CarOutlined } from '@ant-design/icons';

const Address: React.FC = () => {
  return (
    <div>
      <Space direction="vertical" size={20}>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={4}>
            <div style={{ fontSize: '20px', color: '#000000' }}>제이피드림</div>
          </Col>
          <Col xs={24} sm={20}>
            <div style={{ fontSize: '30px', color: '#000000', whiteSpace: 'normal', maxWidth: '100%' }}>
              경기도 화성시 동탄첨단산업1로 27 B동 21층 2151호
            </div>
          </Col>
        </Row>
        <Divider />
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={4}>
            <div style={{ color: '#000000' }}>
              <EnvironmentOutlined /> 오시는길
            </div>
          </Col>
          <Col xs={24} sm={20}>
            <div style={{ color: '#000000' }}>동탄금강펜테리움IX타워 B동 21층</div>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col xs={24} sm={4}>
            <div style={{ color: 'gray' }}>
              <CarOutlined /> 주차정보
            </div>
          </Col>
          <Col xs={24} sm={20}>
            <div style={{ color: 'green' }}>2시간 가능합니다</div>
          </Col>
        </Row>
      </Space>
    </div>
  );
};

export default Address;
