import React from 'react';
import { Button, Input, Form } from 'antd';
import { gql, useMutation } from '@apollo/client';
import { User } from './User.type';

const MUTATION = gql`
  mutation UpdateUser($data: UserUpdateInput!, $userId: String!) {
    updateUser(data: $data, userId: $userId) {
      id
      name
    }
  }
`;

interface Props {
  user: User;
  onCancel: () => void;
  onUpdate: () => void;
}

const EditUser = (props: Props): JSX.Element => {
  const [message, setMessage] = React.useState('');
  const [form] = Form.useForm();

  const [updateUser] = useMutation(MUTATION);

  const editUserF = async () => {
    try {
      const values = await form.validateFields();
      const dataUser = await updateUser({
        variables: {
          data: {
            name: values.firstname,
          },
          userId: props.user.id,
        },
      });

      if (dataUser?.data?.updateUser) {
        setMessage('');
        props.onUpdate();
      }
    } catch (e) {
      console.log(JSON.stringify(e, null, 2));
    }
  };

  return (
    <Form form={form} initialValues={{ firstname: props.user.firstname }}>
      <div>
        <Form.Item label="이름" name="firstname">
          <Input />
        </Form.Item>
        {/* Ant Design does not have an exact equivalent for FormControl and Select */}
      </div>

      <div>
        <Button type="default" onClick={props.onCancel}>
          취소
        </Button>{' '}
        <Button type="primary" onClick={editUserF}>
          저장
        </Button>
        <div className="secondary">{message}</div>
      </div>
    </Form>
  );
};

export default EditUser;
