import React from 'react';
import { useState, useEffect } from 'react';
import { Card } from '@app/components/common/Card/Card';

interface Props {
  src: string;
}

const Product: React.FC<Props> = ({ src }) => {
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImageSize({ width: img.width, height: img.height });
    };
    img.src = src;
  }, [src]);

  return (
    <Card style={{ width: imageSize.width * 0.66 }}>
      <div style={{ width: '100%', margin: '0 auto' }}>
        <img src={src} alt="Product" style={{ width: '100%', height: 'auto' }} />
      </div>
    </Card>
  );
};

export default Product;
