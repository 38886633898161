import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
// import { useAppDispatch } from '@app/hooks/reduxHooks';
// import { doSignUp } from '@app/store/slices/authSlice';
// import { notificationController } from '@app/controllers/notificationController';
// import { ReactComponent as GoogleIcon } from '@app/assets/icons/google.svg';
// import { ReactComponent as FacebookIcon } from '@app/assets/icons/facebook.svg';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import * as S from './SignUpForm.styles';
import { gql, useMutation } from '@apollo/client';
// import { PostsContext } from '@app/Context';
import { message } from 'antd';

interface SignUpFormData {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
}

// const initValues = {
//   firstname: 'Chris',
//   lastname: 'Johnson',
//   email: 'chris.johnson@altence.com',
//   password: 'test-pass',
//   confirmPassword: 'test-pass',
//   termOfUse: true,
// };

// $firstname: String!, $lastname: String!, $email: String!, $password: String!) {
const MUTATION = gql`
  mutation Signup($data: SignupInput!) {
    signup(data: $data) {
      accessToken
      refreshToken
      user {
        id
        firstname
        lastname
        email
      }
    }
  }
`;

export const SignUpForm: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();

  const [signupUser] = useMutation(MUTATION, {
    onCompleted: (data) => {
      console.log('onCompleted', data);
      message.success({
        content: '[' + data.signup.user.email + ']의 사용자가 생성되었습니다.',
        className: 'custom-class',
        style: {
          marginTop: '60vh',
        },
      });
      navigate('/auth/login');
    },
    onError: (err) => {
      setLoading(false);
      const obj = JSON.parse(JSON.stringify(err, null, 2));
      message.error({
        content: obj.message,
        className: 'custom-class',
        style: {
          marginTop: '60vh',
        },
      });
    },
  });

  const handleSubmit = (values: SignUpFormData) => {
    setLoading(true);
    signupUser({
      variables: {
        data: {
          email: values.email,
          firstname: values.firstname,
          lastname: values.lastname,
          password: values.password,
        },
      },
    });
  };

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional">
        <S.Title>{t('common.signUp')}</S.Title>
        <Auth.FormItem
          name="firstname"
          label={t('common.firstname')}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput placeholder={t('common.firstname')} />
        </Auth.FormItem>
        <Auth.FormItem
          name="lastname"
          label={t('common.lastname')}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput placeholder={t('common.lastname')} />
        </Auth.FormItem>
        <Auth.FormItem
          name="email"
          label={t('common.email')}
          rules={[
            { required: true, message: t('common.requiredField') },
            {
              type: 'email',
              message: t('common.notValidEmail'),
            },
          ]}
        >
          <Auth.FormInput placeholder={t('common.email')} />
        </Auth.FormItem>
        <Auth.FormItem
          label={t('common.password')}
          name="password"
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInputPassword placeholder={t('common.password')} />
        </Auth.FormItem>
        <Auth.FormItem
          label={t('common.confirmPassword')}
          name="confirmPassword"
          dependencies={['password']}
          rules={[
            { required: true, message: t('common.requiredField') },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t('common.confirmPasswordError')));
              },
            }),
          ]}
        >
          <Auth.FormInputPassword placeholder={t('common.confirmPassword')} />
        </Auth.FormItem>
        <Auth.ActionsWrapper>
          <BaseForm.Item name="termOfUse" valuePropName="checked" noStyle>
            <Auth.FormCheckbox>
              <Auth.Text>{t('사용 약관 및 개인 정보 보호 정책에 동의합니다')}</Auth.Text>
            </Auth.FormCheckbox>
          </BaseForm.Item>
        </Auth.ActionsWrapper>
        <BaseForm.Item noStyle>
          <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {t('common.signUp')}
          </Auth.SubmitButton>
        </BaseForm.Item>
        {/* <BaseForm.Item noStyle>
          <Auth.SocialButton type="default" htmlType="submit">
            <Auth.SocialIconWrapper>
              <GoogleIcon />
            </Auth.SocialIconWrapper>
            {t('signup.googleLink')}
          </Auth.SocialButton>
        </BaseForm.Item>
        <BaseForm.Item noStyle>
          <Auth.SocialButton type="default" htmlType="submit">
            <Auth.SocialIconWrapper>
              <FacebookIcon />
            </Auth.SocialIconWrapper>
            {t('signup.facebookLink')}
          </Auth.SocialButton>
        </BaseForm.Item> */}
        <Auth.FooterWrapper>
          <Auth.Text>
            {t('이미 계정이 있으신가요?')}{' '}
            <Link to="/auth/login">
              <Auth.LinkText>{t('로그인하기')}</Auth.LinkText>
            </Link>
          </Auth.Text>
        </Auth.FooterWrapper>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
