import React from 'react';
import { HashRouter, Routes, Route } from 'react-router-dom';

import { References } from '@app/components/common/References/References';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
import LoginPage from '@app/pages/LoginPage';
import SignUpPage from '@app/pages/SignUpPage';

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import RequireAuth from '@app/components/router/RequireAuth';
import { withLoading } from '@app/hocs/withLoading.hoc';

import DashboardPage from '@app/pages/DashboardPages/DashboardPage';

import ProductListPage from '@app/pages/ProductListPage';
import QuestionListPage from '@app/pages/QuestionListPage';
import WayHomePage from '@app/pages/WayHomePage';

import Users from '@app/pages/user/Users';

const ServerErrorPage = React.lazy(() => import('@app/pages/ServerErrorPage'));
const Error404Page = React.lazy(() => import('@app/pages/Error404Page'));
const Logout = React.lazy(() => import('./Logout'));

export const DASHBOARD_PATH = '/';

const ProductList = withLoading(ProductListPage);
const QuestionList = withLoading(QuestionListPage);
const WayHome = withLoading(WayHomePage);

const ServerError = withLoading(ServerErrorPage);
const Error404 = withLoading(Error404Page);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  return (
    <HashRouter>
      <header>{protectedLayout}</header>

      <Routes>
        <Route path={DASHBOARD_PATH}>
          <Route index element={<DashboardPage />} />
          <Route path="productList" element={<ProductList />} />
          <Route path="questionList" element={<QuestionList />} />
          <Route path="wayHome" element={<WayHome />} />
          <Route path="users" element={<Users />} />
          <Route path="server-error" element={<ServerError />} />
          <Route path="404" element={<Error404 />} />
        </Route>
        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="sign-up" element={<SignUpPage />} />
        </Route>
        <Route path="/logout" element={<LogoutFallback />} />
      </Routes>
      <References />
    </HashRouter>
  );
};
