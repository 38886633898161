import { gql } from '@apollo/client';

export const CREATE_QUESTION_MUTATION = gql`
  mutation CreateQuestion($data: CreateQuestionInput!) {
    createQuestion(data: $data) {
      name
      phone
      email
      title
      contents
    }
  }
`;

export const UPDATE_QUESTION_MUTATION = gql`
  mutation UpdateQuestion($id: ID!, $data: UpdateQuestionInput!) {
    updateQuestion(id: $id, data: $data) {
      id
      name
      phone
      email
      title
      contents
    }
  }
`;

export const DELETE_QUESTION_MUTATION = gql`
  mutation DeleteQuestion($id: Int!) {
    deleteQuestion(id: $id)
  }
`;

export const GET_QUESTIONLIST_QUERY = gql`
  query GetQuestionList($page: Int!, $search: String!) {
    findManyQuestion(page: $page, search: $search) {
      questions {
        id
        name
        phone
        email
        title
        contents
      }
      count
      take
    }
  }
`;

export const GET_ALLQUESTION_QUERY = gql`
  query GetAllQuestion {
    findAllQuestion {
      id
      name
      phone
      email
      title
      contents
    }
  }
`;
