import React, { useState } from 'react';
import { Input, Row, Col } from 'antd';
import { SearchOutlined, CloseCircleFilled } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';

const Search: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryString = require('query-string');
  const parsed = queryString.parse(location.search);

  const [search, setSearch] = useState(parsed.search || '');

  const searchF = () => {
    parsed.search = search;
    parsed.page = 1;
    navigate('?' + queryString.stringify(parsed));
  };

  return (
    <Row gutter={16} justify="end" align="middle">
      <Col xs={24} md={24}>
        <Input
          placeholder="검색할 단어를 입력하세요"
          style={{ backgroundColor: '#balck', color: '#333' }}
          suffix={
            search ? (
              <CloseCircleFilled
                style={{ cursor: 'pointer', color: '#ff0000' }}
                onClick={() => {
                  setSearch('');
                  delete parsed.search;
                  navigate('?' + queryString.stringify(parsed));
                }}
              />
            ) : (
              <SearchOutlined style={{ cursor: 'pointer', color: '#007bff' }} onClick={searchF} />
            )
          }
          id="search"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
          onPressEnter={searchF}
          value={search}
        />
      </Col>
    </Row>
  );
};

export default Search;
