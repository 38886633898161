import React from 'react';
import * as S from './References.styles';

export const References: React.FC = () => {
  return (
    <S.ReferencesWrapper>
      <S.Text style={{ fontSize: 'var(--font-ss)', fontWeight: 'var(--weight-b)', color: 'var(--gray)' }}>
        Copyright{' '}
        <a href="http://www.jpdream.kr" target="_blank" rel="noreferrer">
          jpdream{' '}
        </a>
        in 2024 &copy;. All rights reserved.
      </S.Text>
      <div style={{ height: '20px' }} />
    </S.ReferencesWrapper>
  );
};
