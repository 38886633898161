import React from 'react';
import { Modal, Form, Input, Table, Row, Col, Select } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Question } from '@app/types/types';
import { useMutation } from '@apollo/client';
import { CREATE_QUESTION_MUTATION, UPDATE_QUESTION_MUTATION } from '@app/graphql/questionMutation';
import { ButtonOKCancel } from '@app/components/common/buttons/Button/ButtonOKCancel';
import { FormConfig, getFormConfig1 } from './QuestionFormConfig';
import InputDatePicker from '@app/components/common/inputs/Input/InputDatePicker';

interface QuestionModalProps {
  question?: Question | null;
  visible: boolean;
  onCancel: () => void;
  onSubmit: (newQuestion: Question) => void;
}

const QuestionModal: React.FC<QuestionModalProps> = ({ question, visible, onCancel, onSubmit }) => {
  const [form] = Form.useForm();
  const [createQuestion] = useMutation(CREATE_QUESTION_MUTATION);
  const [updateQuestion] = useMutation(UPDATE_QUESTION_MUTATION);

  const handleOk = async () => {
    try {
      const values = await form.validateFields();

      const updatedQuestion: Question = {
        ...question,
        ...values,
      };

      if (question?.id) {
        await updateQuestion({
          variables: {
            id: question.id,
            data: values,
          },
        });
      } else {
        await createQuestion({
          variables: {
            data: values,
          },
        });
      }

      form.resetFields();
      onSubmit(updatedQuestion);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const formConfig1: FormConfig[] = getFormConfig1(question);

  const columns: ColumnsType<FormConfig> = [
    {
      title: 'Label',
      dataIndex: 'label',
    },
    {
      title: 'Input',
      dataIndex: 'name',
      render: (_, record) => (
        <Form.Item name={record.name} initialValue={record.defaultValue} style={{ marginBottom: 0 }}>
          {record.inputType === 'textarea' ? (
            <Input.TextArea
              value={form.getFieldValue(record.name)}
              onChange={(e) => form.setFieldsValue({ [record.name]: e.target.value })}
              rows={5}
            />
          ) : record.inputType === 'select' ? (
            <Select
              placeholder={form.getFieldValue(record.name)}
              showSearch
              allowClear
              filterOption={(inputValue, option) =>
                option?.children
                  ? String(option.children).toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                  : false
              }
            >
              {record.name === 'chimneyType' ? (
                <>
                  <Select.Option value="단일">단일</Select.Option>
                  <Select.Option value="복수">복수</Select.Option>
                </>
              ) : record.name === 'questionType' ? (
                <>
                  <Select.Option value="Tinker Question S R2.0">Tinker Question S R2.0</Select.Option>
                  <Select.Option value="Raspberry Pi 4">Raspberry Pi 4</Select.Option>
                </>
              ) : record.name === 'osVersion' ? (
                <>
                  <Select.Option value="Debian 10">Debian 10</Select.Option>
                  <Select.Option value="Ubuntu 20.04">Ubuntu 20.04</Select.Option>
                </>
              ) : null}
            </Select>
          ) : record.inputType === 'date' ? (
            <InputDatePicker name={record.name} placeholder={record.defaultValue} form={form} />
          ) : (
            <Input
              value={form.getFieldValue(record.name)}
              onChange={(e) => form.setFieldsValue({ [record.name]: e.target.value })}
            />
          )}
        </Form.Item>
      ),
    },
  ];

  return (
    <Modal
      title={question ? 'Edit Question' : 'Add Question'}
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={<ButtonOKCancel handleOk={handleOk} handleCancel={handleCancel} />}
      style={{ maxHeight: '70vh', overflowY: 'auto', width: '90%' }}
    >
      <Form form={form}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Table
              dataSource={formConfig1}
              columns={columns}
              size="middle"
              pagination={false}
              rowKey="name"
              showHeader={false}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default QuestionModal;
