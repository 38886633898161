import { Question } from '@app/types/types';
import { UserOutlined, PhoneOutlined, MailOutlined, FileTextOutlined } from '@ant-design/icons';

export interface FormConfig {
  label: string | JSX.Element;
  name: string;
  defaultValue?: string | Date;
  inputType?: 'text' | 'textarea' | 'select' | 'date' | 'upload';
}

export const getFormConfig1 = (question: Question | null | undefined): FormConfig[] => {
  return [
    {
      // eslint-disable-next-line
      label: <span>글쓴이<UserOutlined style={{ marginLeft: '5px' }} /></span>,
      name: 'name',
      defaultValue: question?.name || '',
      inputType: 'text',
    },
    {
      // eslint-disable-next-line
      label: <span>전화번호<PhoneOutlined style={{ marginLeft: '5px' }} /></span>,
      name: 'phone',
      defaultValue: question?.phone || '',
      inputType: 'text',
    },
    {
      // eslint-disable-next-line
      label: <span>이메일<MailOutlined style={{ marginLeft: '5px' }} /></span>,
      name: 'email',
      defaultValue: question?.email || '',
      inputType: 'text',
    },
    {
      // eslint-disable-next-line
      label: <span>제목<FileTextOutlined style={{ marginLeft: '5px' }} /></span>,
      name: 'title',
      defaultValue: question?.title || '',
      inputType: 'text',
    },
    {
      // eslint-disable-next-line
      label: <span>문의사항<FileTextOutlined style={{ marginLeft: '5px' }} /></span>,
      name: 'contents',
      defaultValue: question?.contents || '',
      inputType: 'textarea',
    },
  ];
};
