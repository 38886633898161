import React from 'react';
import WayHome from '@app/components/map/WayHome';
import Address from '@app/components/map/Address';

const WayHomePage: React.FC = () => {
  return (
    <>
      <section className="map_area">
        <div className="inner">
          <WayHome />
        </div>
      </section>

      <section className="map_area">
        <div className="inner">
          <Address />
        </div>
      </section>
    </>
  );
};

export default WayHomePage;
