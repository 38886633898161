import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en/translation.json';
import translationKR from './locales/kr/translation.json';

const resources = {
  en: {
    translation: translationEN,
  },
  kr: {
    translation: translationKR,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'kr',

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
