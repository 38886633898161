import React, { useEffect, useState, useCallback } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useNavigate, useLocation } from 'react-router-dom';
import Search from '../Search';

import UserPage from './UserPage';
import { PostsContext } from '../../Context';

import { Space, TablePaginationConfig } from 'antd';
import { Pagination } from 'api/table.api';
import { User } from './User.type';
import { Table } from 'components/common/Table/Table';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { useMounted } from '@app/hooks/useMounted';
import DeleteUser from './DeleteUser';

/* eslint-disable */

export const QUERY = gql`
  query UsersPagination($page: Float!, $where: UserWhereInput) {
    usersPagination(page: $page, where: $where) {
      users {
        id
        name
        email
      }
      count
      take
    }
  }
`;

const initialPagination: Pagination = {
  current: 1,
  pageSize: 5,
  showSizeChanger: false
};

const Users = () => {
  const navigate = useNavigate();
  const queryString = require("query-string");
  const context = React.useContext(PostsContext);
  const location = useLocation();
  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const parsed = queryString.parse(location.search);
  const page = parsed.page ? Number(parsed.page) : 1;

  const { data } = useQuery(QUERY, {
    variables: {
      where: {
        search: parsed.search,
      },
      page,
    },
  });

  const [tableData, setTableData] = useState<{ data: User[]; pagination: Pagination; loading: boolean }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });

  const fetch = useCallback(
    (pagination: Pagination) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));
      if (isMounted.current) {
        console.log('fetch', pagination);
        setTableData({ data: data?.usersPagination?.users, pagination: pagination, loading: false });
      }
    },
    [isMounted, data],
  );

  useEffect(() => {
    initialPagination.current = page;
    initialPagination.pageSize = data?.usersPagination?.take;
    initialPagination.total = data?.usersPagination?.count;

    fetch(initialPagination);
  }, [fetch]);

  const handleTableChange = (pagination: TablePaginationConfig) => {
    parsed.page = pagination.current;
    navigate('?' + queryString.stringify(parsed));
  };

  const columns: ColumnsType<User> = [
    {
      title: t('users.name'),
      dataIndex: 'name',
      align: 'center' as const,
    },
    {
      title: t('users.email'),
      dataIndex: 'email',
      align: 'center' as const,
    },
    {
      title: t('users.role'),
      dataIndex: 'role',
      align: 'center' as const,
    },
    {
      title: t('users.lastLogin'),
      dataIndex: 'lastLogin',
      align: 'center' as const,
    },
    {
      title: t('users.edit'),
      dataIndex: 'actions',
      align: 'center' as const,
      width: '20%',
      render: (text: string, record: User) => {
        return (
          <Space>
            <UserPage user={ record }/>
          </Space>
        );
      },
    },
    {
      title: t('users.delete'),
      dataIndex: 'actions',
      align: 'center' as const,
      width: '10%',
      render: (text: string, record: User) => {
        return (
          <Space>
            <DeleteUser userId={record.id}/>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <h3>{t('users.list')}</h3>
      <Search />
      <div style={{ height: "15px" }} />
      {data?.usersPagination?.users && (
        <>
          <Table
            columns={columns}
            dataSource={tableData.data}
            pagination={tableData.pagination}
            loading={tableData.loading}
            onChange={handleTableChange}
            scroll={{ x: 800 }}
            bordered
          />
        </>
      )}
    </>
  );
};
export default Users;
