import { WithChildrenProps } from '@app/types/generalTypes';
import React from 'react';
// import { Helmet } from 'react-helmet';
import { Helmet, HelmetProvider } from 'react-helmet-async';

// eslint-disable-next-line
export const PageTitle: React.FC<WithChildrenProps> = ({ children }) => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>(주)제이피드림</title>
      </Helmet>
    </HelmetProvider>
  );
};
