import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addDeferredPrompt } from '@app/store/slices/pwaSlice';

export const usePWA = (): void => {
  const dispatch = useDispatch();

  useEffect(() => {
    const handler = (e: Event) => {
      e.preventDefault();

      // Extract serializable data from the event
      const serializedEvent = {
        isTrusted: e.isTrusted,
        // Add other properties you need
      };

      // Dispatch the action with the serialized event
      dispatch(addDeferredPrompt(serializedEvent));
    };

    window.addEventListener('beforeinstallprompt', handler);

    // Cleanup: Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeinstallprompt', handler);
    };
  }, [dispatch]);
};
