import React from 'react';
import { gql, useMutation, useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Button, Popconfirm } from 'antd';
import { UserDeleteOutlined } from '@ant-design/icons';

/* eslint-disable */

const MUTATION = gql`
  mutation DeleteUser($userId: String!) {
    deleteUser(userId: $userId) {
      id
    }
  }
`;

interface Props {
  userId: string;
}
const DeleteUser = (props: Props) => {
  const client = useApolloClient();
  const [message, setMessage] = React.useState("");
  const { t } = useTranslation();

  const [deleteUser] = useMutation(MUTATION);

  const deleteUserF = async () => {
    let dataUser;
    try {
      dataUser = await deleteUser({
        variables: {
          userId: props.userId,
        },
      });
    } catch (e) {
      console.log(JSON.stringify(e, null, 2));
    }
    if (dataUser?.data?.deleteUser) {
      setMessage("");
      client.resetStore();
    }
  };

  return (
    <>
      <Popconfirm title={t('common.deleteQuestion')} onConfirm={deleteUserF}>
        <Button type="link" style={{ color: 'red' }} icon={<UserDeleteOutlined />} />
      </Popconfirm>
      <div className="secondary">{message}</div>
    </>
  );
};
export default DeleteUser;
