import styled from 'styled-components';
import { Card as AntCard } from 'antd';
// import { normalizeProp } from 'utils/utils';
import { media } from '@app/styles/themes/constants';

interface CardInternalProps {
  $padding: string | number | [number, number];
  $autoHeight: boolean;
}

export const Card = styled(AntCard)<CardInternalProps>`
  width: 100%;
  padding: 1.25rem;
  background: var(--table_box);

  @media only screen and ${media.xs} and (max-width: 1280px) {
    padding: 0.6rem;
  }

  ${(props) => props.$autoHeight && 'height: 100%'};
  .ant-card-head {
    .ant-card-head-title {
      white-space: unset;
      overflow: unset;
      font-size: var(--font-m);
      font-weight: var(--weight-b);
    }

    .ant-card-head-title,
    .ant-card-extra {
      padding: 0;
    }

    @media all and ${media.xl} {
      font-size: var(--font-l);
    }
  }

  .ant-card-body {
    display: block;
    flex-grow: 1;
    padding: 0;
    background: #2e303c;

    .ant-table,
    .ant-row,
    .ant-table-thead > tr > th {
      color: var(--white);
      // font-size: var(--font-s);

      @media only screen and ${media.xs} and (max-width: 1280px) {
        font-size: var(--font-ss);
      }
    }

    .ant-row {
      display: flex;

      .ant-col {
        border: 1px solid var(--table_dark_line);

        .ant-card-bordered {
          background: none !important;
          border: 0;
        }
      }

      .ant-col-8 {
        border: 0;

        div {
          justify-content: center !important;
        }
      }
    }
  }
`;
