import React from 'react';
import { ButtonProps as AntButtonProps, Button as AntdButton } from 'antd';

export interface ButtonProps extends AntButtonProps {
  handleCancel: () => void;
  handleOk: () => void;
}

export const ButtonOKCancel: React.FC<ButtonProps> = ({ handleCancel, handleOk }) => (
  <>
    <AntdButton key="cancel" onClick={handleCancel}>
      취소
    </AntdButton>
    <AntdButton key="submit" type="primary" onClick={handleOk}>
      저장
    </AntdButton>
  </>
);
